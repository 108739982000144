import React from 'react';
import styled from 'styled-components';
import Section from './Section';
//import carinfos from '../files/carinfo';

function Home() {
    return (
        <Container>
            <Section 
                title='Model S'
                description='Order Online for Touchless Delivery'
                backgroundImg='model-s.jpg'
                leftBtnText='Custom Order'
                rightBtnText='Existing Inventory'
            />
            <Section 
                title='Model 3'
                description='Order Online for Touchless Delivery'
                backgroundImg='model-3.jpg'
                leftBtnText='Custom Order'
                rightBtnText='Existing Inventory'
            />
            <Section 
                title='Model X'
                description='Order Online for Touchless Delivery'
                backgroundImg='model-x.jpg'
                leftBtnText='Custom Order'
                rightBtnText='Existing Inventory'
            />
            <Section 
                title='Model Y'
                description='Order Online for Touchless Delivery'
                backgroundImg='model-y.jpg'
                leftBtnText='Custom Order'
                rightBtnText='Existing Inventory'
            />
            <Section
                title='Lowest Cost Solar Panels in America'
                description='Money-back guarantee'
                backgroundImg='solar-panel.jpg'
                leftBtnText='Order now'
                rightBtnText='Learn more'
            />
            <Section
                title='Solar for New Roofs'
                description='Solar Roof Costs Less Than a New Roof Plus Solar Panels'
                backgroundImg='solar-roof.jpg'
                leftBtnText='Order now'
                rightBtnText='Learn more'
            />
            <Section
                title='Accessories'
                backgroundImg='accessories.jpg'
                leftBtnText='Shop now'
            />
        </Container>
    )
}

const Container = styled.div`
height: 100vh;
`;

/* function Cars() {
    const carlist = [];
    carinfos.forEach((car) => {
        carlist.push(            
            <Section 
                title={car.title}
                description={car.description}
                backgroundImg={car.image}
                leftBtnText='Custom Order'
                rightBtnText='Existing Inventory'
            />
        );
    });
    return carlist;
} */

export default Home;